import logo from './logo.svg';
import './App.css';
import Router from './router';
import 'animate.css/animate.min.css';

function App() {
  return (
    <div>
      <Router/>
    </div>
  );
}

export default App;
