import { lazy } from "react";
const HomePage = lazy(() => import('../pages/Home'))
const DownLoaderPage = lazy(() => import('../pages/Downloader'))
const CurrencyConverter = lazy(() => import('../pages/Utility/CurrencyConverter'))
const Weather = lazy(() => import('../pages/Utility/Weather'))
const SegmentSaver = lazy(() => import('../pages/SegmentSaver'))
const ImageConversion = lazy(() => import('../pages/ImageConversion'))
const DocPdFConversion = lazy(() => import('../pages/FileConversion'))
const AboutUs = lazy(() => import('../pages/AboutUs'))
const ContactUs = lazy(() => import('../pages/ContactUs'))

export const routes = [
            {
                path:'/',
                element:<HomePage />
            },
            {
                path:'/video-downloader',
                element:<DownLoaderPage />
            },
            {
                path:'/facebook-video-downloader',
                element:<DownLoaderPage />
            },
            {
                path:'/youtube-video-downloader',
                element:<DownLoaderPage />
            },
            {
                path:'/instagram-video-downloader',
                element:<DownLoaderPage />
            },
            {
                path:'/segment-saver',
                element:<SegmentSaver />
            },
            {
                path:'/utility/currency-converter',
                element:<CurrencyConverter />
            },
            {
                path:'/utility/weather',
                element:<Weather />
            },
            {
                path:'/image-converter',
                element:<ImageConversion />
            },
            {
                path:'/file-converter',
                element:<DocPdFConversion />
            },
            {
                path:'/about-us',
                element:<AboutUs />
            },
            {
                path:'/contact-us',
                element:<ContactUs />
            },
            {
                path:'*',
                element:<HomePage />
            }
]