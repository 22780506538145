import React, { useEffect, useRef } from "react";
import Hero from "../../components/Hero";
import TrendingVideosGrid from "../../components/TrendingVideosGrid";
import UtilityGrid from "../../components/UtilityGrid";
import FeatureHighlights from "../../components/Features";
import HowItWorks from "../../components/HowItWorks";
import AdComponent from "../../components/AdComponet";
const Home = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    // Function to add 'animate__animated' class when elements are in view
    const handleScroll = () => {
      if (
        ref1.current &&
        ref1.current.getBoundingClientRect().top < window.innerHeight
      ) {
        ref1.current.classList.add("animate__animated", "animate__slideInUp");
      }
      if (
        ref2.current &&
        ref2.current.getBoundingClientRect().top < window.innerHeight
      ) {
        ref2.current.classList.add("animate__animated", "animate__slideInUp");
      }
      if (
        ref3.current &&
        ref3.current.getBoundingClientRect().top < window.innerHeight
      ) {
        ref3.current.classList.add("animate__animated", "animate__slideInUp");
      }
    };

    // Event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Hero />
      <section ref={ref1} className=" animate__delay-0.7s">
        <FeatureHighlights />
      </section>
      <AdComponent />
      {/* <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9737244552705500"
        crossOrigin="anonymous"
      ></script>
      <ins
        class="adsbygoogle"
        style= {{display:"block"}}
        data-ad-client="ca-pub-9737244552705500"
        data-ad-slot="2620164874"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script> */}
      <section ref={ref2} className=" animate__delay-0.7s">
        <HowItWorks />
      </section>
      {/* <TrendingVideosGrid />
      <UtilityGrid /> */}
    </div>
  );
};

export default Home;
