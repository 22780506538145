import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste, faEye, faDownload } from '@fortawesome/free-solid-svg-icons'; // Import necessary icons
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HowItWorks = () => {
  const features = [
    {
      title: 'Video Downloader',
      description: 'Easily download videos from YouTube and Facebook.',
      buttonColor: '#32a852', // Green button background color
      iconColor: '#32a852', // Green icon color
      link: '/video-downloader',
      icon: faPaste,
      steps: [
        'Paste Video URL: Paste the URL of the video you want to download.',
        'Preview Video: See a preview of the video to confirm your selection.',
        'Download Options: Choose the format and quality for your download.',
        'Click to Download: Initiate the download process with a click.',
        'Download Confirmation: Receive confirmation once the download is complete.',
      ],
    },
    {
      title: 'Segment Saver',
      description: 'Download specific segments of a video.',
      buttonColor: '#32a852', // Green button background color
      iconColor: '#32a852', // Green icon color
      link: '/segment-saver',
      icon: faEye,
      steps: [
        'Paste Video URL: Paste the URL of the video to select a specific segment.',
        'Preview Segment: Preview the selected segment of the video.',
        'Segment Selection: Specify the start time and duration for the segment.',
        'Click to Save Segment: Save the selected segment of the video.',
        'Save Confirmation: Receive confirmation once the segment is saved.',
      ],
    },
    {
      title: 'File Converter',
      description: 'Convert between different file formats.',
      buttonColor: '#32a852', // Green button background color
      iconColor: '#32a852', // Green icon color
      link: '/file-converter',
      icon: faDownload,
      steps: [
        'Upload File: Select the file you want to convert.',
        'Choose Conversion Format: Specify the format you want to convert the file to.',
        'Convert File: Initiate the conversion process.',
        'Download Converted File: Download the converted file once the process is complete.',
        'Conversion Confirmation: Receive confirmation once the file conversion is done.',
      ],
    },
    {
      title: 'Image Converter',
      description: 'Convert images between various formats.',
      buttonColor: '#32a852', // Green button background color
      iconColor: '#32a852', // Green icon color
      link: '/image-converter',
      icon: faDownload,
      steps: [
        'Upload Image: Select the image you want to convert.',
        'Choose Conversion Format: Specify the format you want to convert the image to.',
        'Convert Image: Initiate the conversion process.',
        'Download Converted Image: Download the converted image once the process is complete.',
        'Conversion Confirmation: Receive confirmation once the image conversion is done.',
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="how-it-works" className="py-20 bg-gray-100 text-gray-700">
      <div className="container mx-auto">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">How It Works</h2>
          <Slider {...sliderSettings}>
            {features.map((feature, index) => (
              <div key={index} className="px-2">
                <div className={`bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transform transition duration-300 text-gray-700 h-full`}>
                  <h3 className="text-2xl font-bold mb-2">{feature.title}</h3>
                  <p className="mb-4">{feature.description}</p>
                  <ol className="text-left list-decimal pl-6">
                    {feature.steps.map((step, i) => (
                      <li key={i} className="mb-4">
                        <FontAwesomeIcon icon={feature.icon} className="mr-2" style={{ color: feature.iconColor }} />
                        {step}
                      </li>
                    ))}
                  </ol>
                  <Link to={feature.link} className={`bg-[#32a852] text-white px-4 py-2 rounded mt-4 inline-block self-center`}>Use {feature.title}</Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
