// src/components/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, author = "",path = "" }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
   <meta property="og:description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name = "author" content= {author}/>
   <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://www.utilityhub.site/${path}`} />
  </Helmet>
  
);

export default SEO;
