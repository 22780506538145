import React from 'react';
import { Link } from 'react-router-dom';

const footerArray = [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "Facebook Downloader",
    link: "/facebook-video-downloader"
  },
  {
    name: "Youtube Downloader",
    link: "/youtube-video-downloader"
  },
  // {
  //   name: "Instagram Downloader",
  //   link: "/instagram-video-downloader"
  // },
  {
    name: "Segment Saver",
    link: "/segment-saver"
  },
  {
    name: "File Converter",
    link: "/file-converter"
  },
  {
    name: "Image Converter",
    link: "/image-converter"
  },
  {
    name: "About Us",
    link: "/about-us"
  },
  {
    name: "Contact Us",
    link: "/contact-us"
  }
];

const Footer = () => {
  return (
    <footer id="footer" className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        <div className="flex flex-wrap justify-center gap-4">
          {footerArray.map((item, index) => (
            <Link key={index} to={item.link} className="text-white hover:bg-yellow-400 hover:text-gray-900 transition duration-300 ease-in-out rounded-md px-3 py-2 mb-2 md:mb-0">
              {item.name}
            </Link>
          ))}
        </div>
        {/* Social media icons or additional links */}
        {/* <div className="mt-4">
          <a href="https://facebook.com" className="mx-2"><i className="fab fa-facebook"></i></a>
          <a href="https://twitter.com" className="mx-2"><i className="fab fa-twitter"></i></a>
          <a href="https://instagram.com" className="mx-2"><i className="fab fa-instagram"></i></a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
