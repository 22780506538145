import React, { useTransition, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Home from "../pages/Home";
// import Contact from './pages/Contact';
import { routes } from "./router";
const RouterApp = () => {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      // errorElement: <Page404 />,
      children: routes
    },
  ])

  return (
      <RouterProvider router={router} />
  )
};

export default RouterApp;
