import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import ToastComponent from "../Toast";
import usePageTracking from '../../usePageTracking';

const Layout = ({ children }) => {
  usePageTracking()
  const PageLoader = () => {
    return <div>Loading...</div>;
  };
  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#f8fafb] group/design-root overflow-x-hidden"
      style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}
    >
      {/* <div className="layout-container flex-grow h-full grow flex-col"> */}
        <div className="flex-grow">
        {" "}
        <Header />
        <ToastComponent />
        <Suspense fallback={<PageLoader />}>
          <Outlet />
        </Suspense>
      </div>
        <Footer />
    </div>
  );
};

export default Layout;
