// // store.js
// import { createStore, applyMiddleware } from 'redux';
// import {thunk} from 'redux-thunk'; // If you need to use async actions
// import rootReducer from './rootReducer'; // Adjust the path as necessary

// const store = createStore(rootReducer, applyMiddleware(thunk));

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import commonSlice from './reducer/commonSlice';

export default configureStore({
  reducer: {
    common: commonSlice,
  },
});
