// Toast.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToastMsg } from '../../redux/reducer/commonSlice'; // Adjust the path as necessary

const Toast = () => {
  const dispatch = useDispatch();
  const toastMsg = useSelector((state) => state.common.toastMsg);
    useEffect(() => {
      if(Object.keys(toastMsg).length){
        setTimeout(() => {
          dispatch(setToastMsg({}));
        },10000)
      }
      
    },[toastMsg])
  const handleClose = () => {
    dispatch(setToastMsg({}));
  };

  if (!Object.keys(toastMsg).length) return null;
  return (
    <div className="fixed top-70 right-5 z-50">
      <div className={`flex items-center ${toastMsg?.type == "error" ? "bg-red-500" : "bg-green-500"} text-white text-sm font-bold px-4 py-3 shadow-md rounded`} role="alert">
        <svg
          className="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M10 15a1.5 1.5 0 001.5-1.5h-3A1.5 1.5 0 0010 15zm.91-3.41a.88.88 0 01-.16.16h-.3a.91.91 0 01-.16-.16L7 8.66V6.67c0-.43.34-.79.75-.79h4.5c.41 0 .75.36.75.79v1.99l-2.59 2.93z" />
        </svg>
        <p>{toastMsg?.msg}</p>
        <button onClick={handleClose} className="ml-4 text-lg leading-none">&times;</button>
      </div>
    </div>
  );
};

export default Toast;
