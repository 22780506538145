import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FeatureHighlights = () => {
  const featureSections = [
    {
      name: 'Video Downloader',
      description: 'Easily download videos from YouTube and Facebook.',
      link: '/video-downloader',
    },
    {
      name: 'Segment Saver',
      description: 'Download specific segments of a video.',
      link: '/segment-saver',
    },
    {
      name: 'File Converter',
      description: 'Convert between different file formats.',
      link: '/file-converter',
    },
    {
      name: 'Image Converter',
      description: 'Convert images between various formats.',
      link: '/image-converter',
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="features" className="py-20 bg-gray-100 text-gray-700">
      <div className="container mx-auto text-center">
        <div className="max-w-5xl mx-auto text-center">

        <h3 className="text-4xl font-bold mb-12 text-gray-900">Our Features</h3>
        <Slider {...sliderSettings}>
          {featureSections.map((feature, index) => (
            <div key={index} className="px-2">
              <div
                className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transform transition duration-300"
              >
                <h4 className="text-2xl md:text-3xl font-bold mb-2 md:mb-4 text-[#ff9934]">{feature.name}</h4>
                <p className="mb-4 md:mb-6 text-base md:text-lg text-gray-800">{feature.description}</p>
                <Link
                  to={feature.link}
                  className="block bg-[#ff9934] hover:bg-[#ff9934] text-white py-2 md:py-3 rounded-lg font-semibold text-lg md:text-base shadow-md transition duration-300"
                >
                  Use {feature.name}
                </Link>
              </div>
            </div>
          ))}
        </Slider>
        </div>
      </div>
    </section>
  );
};

export default FeatureHighlights;
