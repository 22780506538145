import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'commom',
  initialState: {
    loading:false,
    toastMsg:{}
  },
  reducers: {
    showLoader:(state,action)=>{
       state.loading = action.payload
    },
    setToastMsg:(state,action)=>{
      console.log("hererere")
      state.toastMsg = { ...action.payload };
    }
  },
});

export const { showLoader,setToastMsg } = commonSlice.actions;

export default commonSlice.reducer;