import React from "react";
import { Helmet } from "react-helmet-async";

const AdComponent = () => (
  <div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9737244552705500"
        crossOrigin="anonymous"
      ></script>
      <ins
        className="adsbygoogle"
        style= {{display:"block"}}
        data-ad-client="ca-pub-9737244552705500"
        data-ad-slot="2620164874"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
  </div>
);

export default AdComponent;
