import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../Seo';
import BackGroundImage from '../../assets/background.jpg'
const Hero = () => {
  return (
    <section className="bg-gradient-to-r from-blue-500 to-purple-500 bg-cover bg-center h-screen text-white flex items-center" style={{ backgroundImage: `url(${BackGroundImage})`}}>
      <SEO 
          title={"Utility Hub - Download Videos & Segments Easily"}
          description={"Download full videos or specific segments from YouTube, and Facebook with ease."}
          keywords = {"video downloader, segment saver, YouTube downloader downloader, Facebook downloader"}
          author = {"Utility Hub"}
        />
      <div className="container mx-auto text-center">
        <h2 className="text-5xl font-bold mb-4">Ultimate Utility Hub</h2>
        <p className="text-xl mb-8">Download full videos or specific segments from YouTube and Facebook with ease.</p>
        <a href="#features" className="bg-blue-500 px-6 py-3 rounded-full text-white font-bold mr-4">Get Started</a>
        <a href="#how-it-works" className="bg-transparent border border-white px-6 py-3 rounded-full text-white font-bold">Learn More</a>
      </div>
    </section>
  );
};

export default Hero;
