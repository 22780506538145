import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom'
import Logo from '../../assets/Utility.png'

const headerArray = [
      {
        name: "Home",
        link: "/"
      },
      {
        name: "Image Converter",
        link: "/image-converter"
      },
      {
        name: "Segment Saver",
        link: "/segment-saver"
      },
      {
        name: "Facebook Downloader",
        link: "/facebook-video-downloader"
      },
      {
        name: "Youtube Downloader",
        link: "/youtube-video-downloader"
      },
      {
        name: "File Converter",
        link: "/file-converter"
      },
      {
        name: "About Us",
        link: "/about-us"
      },
      {
        name: "Contact Us",
        link: "/contact-us"
      },
]
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="sticky top-0 z-50 shadow-md bg-gradient-to-r from-yellow-500 to-green-500 text-white py-4">
      <div className="container mx-auto px-4 flex items-center justify-between">
        {/* Logo and Branding */}
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-10 md:h-12 mr-2" />
          <h1 className="text-xl md:text-2xl font-bold">Utility Hub</h1>
        </Link>

        {/* Hamburger Menu Button (visible on mobile) */}
        <button
          className="block md:hidden text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>

        {/* Navigation Links */}
        <nav className={`md:flex md:items-center ${isOpen ? 'block bg-yellow-500' : 'hidden'} absolute top-16 right-0  text-white w-full md:w-auto md:relative md:top-auto md:right-auto`}>
          <div className="flex flex-col md:flex-row md:space-x-4 md:items-center md:justify-end p-4 md:p-0">
            {headerArray.map((item, index) => (
              <Link key={index} to={item.link} className="text-white hover:bg-yellow-400 hover:text-gray-900 transition duration-300 ease-in-out rounded-md px-3 py-2 mb-2 md:mb-0 md:mt-0">
                {item.name}
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
